import { isSSR } from './ssr'

type TrackObject = {
  event: string | undefined
  [key: string]: any
}

export function track(obj: TrackObject) {
  if (!isSSR) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(obj)
  }
}
